import { useParams } from "react-router-dom";

function useGetHostesseId(hostessesArray) {

  const { id: idParam, name: nameParam } = useParams();

  const filteredHostesse = hostessesArray.filter(hostesse => hostesse.id === Number(idParam) && hostesse.name === nameParam);

  const hostesseProfil = filteredHostesse.length ? filteredHostesse : false

  if (hostesseProfil) {
    return hostesseProfil;
  }
  return false;
}

export default useGetHostesseId;