import ReactGA from 'react-ga4';
import useScreenSize from '../hooks/useScreenSize';
import { NavLink } from 'react-router-dom';
import logo from '../img/logo-allodial.webp';
import sva from '../img/sva-allodial.webp';
import { Image, Container, Row, Col } from 'react-bootstrap';

function Header() {

  const { width } = useScreenSize();

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <header id="header" className='sticky-top px-2 py-2'>
      <Container fluid={"lg"}>
        <Row className='align-items-center justify-content-between'>
          <Col xs={4} md={2} className='ps-0'>
            <NavLink to={"/"}>
              <Image className='logo-sva' src={logo} alt="Logo allodial.fr" />
            </NavLink>
          </Col>
          <Col xs={8} md={{ span: 3 }} className='pe-0 text-end'>
            {width < 767 ?
              <a href="tel:0895900200" onClick={() => handleClick({ category: "SVA Standard France - Header", action: "Click to Call" })}>
                <Image className="shadow logo-sva" id="sva" src={sva} rounded alt='Numéro téléphone rose : 0895900200' />
              </a>
              :
              <Image className="shadow logo-sva" id="sva" src={sva} rounded alt='Numéro téléphone rose : 0895900200' />
            }
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header;