import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import useScreenSize from "../hooks/useScreenSize";
import { Image, Container, Row, Col } from "react-bootstrap";
import logo from '../img/logo-allodial-white.webp';
import screenshotTelrose from '../img/screenshot-telrose-duo.webp';
import screenshotTelroseMobile from '../img/screenshot-telrose-duo576.webp';
import Carousel from "../components/Carousel";
import Card from "../components/Card";
import svaBelgique from '../img/telrose_Belgique.webp';
import svaLuxembourg from '../img/telrose_Luxembourg.webp';
import svaSuisse from '../img/telrose_Suisse.webp';

function Home({ scrollToHostesses, setScrollToHostesses }) {

  const { width } = useScreenSize();

  function scrollToSection() {
    window.scrollTo(0, document.getElementById('section-hostesses').getBoundingClientRect().y + window.scrollY - document.getElementById("header").offsetHeight);
  }

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <>
      <Helmet>
        <title>AlloDial.fr - Services de Téléphone Rose 24/24 | 0895900200</title>
        <meta name="description"
          content="Découvrez AlloDial.fr, votre service de téléphone rose disponible 24/7. Profitez d'une expérience discrète et sensuelle avec nos hôtesses qualifiées. Satisfaction et plaisir garantis à chaque appel." />
        <link rel="canonical" href="https://allodial.fr" />
        <link rel="preload" as="image" href="https://allodial.fr/static/media/carousel(1)576.bad24d6de6469f975d4a.webp" type="image/webp"></link>
        <link rel="preload" as="image" href="https://allodial.fr/static/media/carousel(1).20516dca324b7278a3fe.webp" type="image/webp"></link>
      </Helmet>
      <Container as={"section"} className="px-0 section-white" fluid={"lg"}>
        <Row className="me-0">
          <Col xs={{ span: 4, offset: 4 }} md={{ span: 2, offset: 5 }} className="text-center" >
            <Image className="logo-sva" src={logo} alt="Logo allodial.fr" />
          </Col>
        </Row>
        <h1 className="fw-bold text-center title mb-0 pb-1">Bienvenue sur <span id="title">allodial.fr</span></h1>
        <Carousel />
      </Container>
      <Container as={"section"} className="py-4 section-white" fluid={"lg"}>
        <article className="text-center">
          <h2 className="title-article text-center py-2"><strong>Assouvissez vos fantasmes en toute discrétion</strong></h2>
          <h3 className="mb-4 subtitle">Bienvenue dans un monde où vos fantasmes prennent vie, où chaque désir secret est écouté et exploré sans jugement</h3>
          <p>Au bout du fil, des voix envoûtantes vous attendent, prêtes à vous emmener dans un voyage au-delà de l'imaginaire.</p>
          <p>Fermez les yeux et laissez-vous emporter par vos désirs les plus profonds. Que vous rêviez de moments passionnés sous la lueur de la lune, de scénarios audacieux dans des lieux insolites, ou simplement de conversations intimes avec une âme complice, nos <span className="link" onClick={() => scrollToSection()}>hôtesses</span> sont là pour vous accompagner.</p>
          <p>Explorez sans tabou vos fantasmes les plus fous, sans crainte d'être jugé. Notre priorité est votre confort et votre satisfaction, alors laissez-vous aller à l'exploration de vos désirs les plus secrets.</p>
          <p>Que vous cherchiez un moment de détente sensuelle, une évasion érotique ou simplement une conversation stimulante, vous trouverez satisfaction au bout du fil. Laissez-nous être votre complice dans l'exploration de vos fantasmes les plus profonds.</p>
          <p>Plongez dans l'aventure dès maintenant. Osez composer le <strong><a href="tel:0895900200" className="link">0 895 900 200</a></strong> et laissez vos fantasmes devenir réalité.</p>
        </article>
      </Container>
      <Container as={"section"} className="py-4 section-grey" fluid={"lg"}>
        <article className="text-center">
          <h2 className="title-article text-center py-2"><strong>Nos hôtesses sont là pour vous guider</strong></h2>
          <h3 className="mb-4 subtitle">Notre site, c'est votre destination ultime pour des conversations téléphoniques sensuelles et captivantes</h3>
          <p>Nos <span className="link" onClick={() => scrollToSection()}>hôtesses</span>, toutes plus séduisantes les unes que les autres, sont prêtes à vous offrir une expérience inoubliable. Chacune possède un charme unique et une voix envoûtante, prête à réaliser vos désirs les plus secrets et à vous accompagner dans des moments de plaisir intense.</p>
          <p>Capables de transformer chaque appel en une évasion passionnée, nos <span className="link" onClick={() => scrollToSection()}>hôtesses</span> sont des expertes en l'art de la conversation érotique. Que vous recherchiez la douceur d'une compagne attentionnée, la fougue d'une amante audacieuse, ou l'exotisme d'une voix enchanteresse, vous trouverez ici la parfaite correspondance à vos envies.</p>
          <p>Laissez-vous tenter par l'expérience ultime du téléphone rose, où chaque conversation est une invitation au rêve et à la sensualité. Nos <span className="link" onClick={() => scrollToSection()}>hôtesses</span> n'attendent que vous pour débuter cette aventure intime et envoûtante.</p>
        </article>
      </Container>
      <Container as={"section"} id="section-hostesses" className="py-4 section-white" fluid={"lg"}>
        <article className="text-center">
          <h2 className="title-article text-center py-2"><strong>Les hôtesses</strong></h2>
          <h3 className="mb-4 subtitle">Explorez leurs profils et découvrez leurs spécialités, de la tendresse romantique aux fantasmes les plus osés</h3>
          <p>Avec une discrétion absolue et une disponibilité à toute heure, nos hôtesses sont prêtes à répondre à vos appels et à faire de chaque échange un moment unique de complicité et de plaisir.</p>
          <hr width="75%" />
          <Card setScrollToHostesses={setScrollToHostesses} />
        </article>
      </Container>
      <Container as={"section"} id="section-hostesses" className="py-4 section-grey" fluid={"lg"}>
        <article className="text-center">
          <h2 className="title-article text-center py-2"><strong>Pour les appels depuis l'étranger</strong></h2>
          {width < 767 ?
            <>
              <div>
                <a href="tel:090340840" onClick={() => handleClick({ category: "SVA Belgique - Page de profil", action: "Click to Call" })}>
                  <Image className="shadow my-2" width={200} src={svaBelgique} rounded alt="numéro cliquable de téléphone rose Belgique : 090340840" />
                </a>
              </div>
              <div>
                <a href="tel:90528006" onClick={() => handleClick({ category: "SVA Luxembourg - Page de profil", action: "Click to Call" })}>
                  <Image className="shadow my-2" width={200} src={svaLuxembourg} rounded alt="numéro cliquable de téléphone rose Luxembourg : 90528006" />
                </a>
              </div>
              <div>
                <a href="tel:0901494494" onClick={() => handleClick({ category: "SVA Suisse - Page de profil", action: "Click to Call" })}>
                  <Image className="shadow my-2" width={200} src={svaSuisse} rounded alt="numéro cliquable de téléphone rose Suisse : 0901494494" />
                </a>
              </div>
            </>
            :
            <>
              <div>
                <Image className="shadow my-2" width={250} src={svaBelgique} rounded alt="numéro de téléphone rose Belgique : 090340840" />
              </div>
              <div>
                <Image className="shadow my-2" width={250} src={svaLuxembourg} rounded alt="numéro de téléphone rose Luxembourg : 90528006" />
              </div>
              <div>
                <Image className="shadow my-2" width={250} src={svaSuisse} rounded alt="numéro de téléphone rose Suisse : 0901494494" />
              </div>
            </>
          }
        </article>
      </Container>
      <Container as={"section"} className="text-light section-pub py-3" fluid={"lg"}>
        <article className="text-center">
          <h2 className="text-center mb-2 py-4 px-2"><strong>Découvrez notre site partenaire <span id="neon-title">telrose-duo.fr</span></strong></h2>
          <a href="https://telrose-duo.fr/" target="_blank" rel="noreferrer"
            onClick={() => handleClick({ category: "Capture d'écran : telrose-duo.fr' - Page Accueil", action: "Click to Link" })} ><Image className="mb-4" src={width < 576 ? screenshotTelroseMobile : screenshotTelrose} fluid alt="Capture d'écran du site partenaire : telrose-duo.fr" /></a>
          <h3 className="mb-4 subtitle-pub px-1"><a className="link-pub" href="https://telrose-duo.fr/" target="_blank" rel="noreferrer">Telrose-duo.fr</a> est un site web de service de téléphonie destiné à ceux qui cherchent des dialogues privés et personnalisés avec des hôtesses professionnelles</h3>
          <a className="text-decoration-none" href="https://telrose-duo.fr/" target="_blank" rel="noreferrer"
            onClick={() => handleClick({ category: "Bouton 'Accéder au site' - Page Accueil", action: "Click to Link" })}><button className="m-3 link-to-site">Accéder au site</button></a>
        </article>
      </Container>
    </>
  )
}

export default Home;