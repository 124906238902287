import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import useScreenSize from "../hooks/useScreenSize";
import useFilterByIdParam from "../hooks/useFilterByIdParam";
import ReactGA from 'react-ga4';
import hostessesArray from "../constants/HostessesArray";
import { Container, Image } from "react-bootstrap";
import svaFrance from '../img/sva-allodial.webp';
import svaBelgique from '../img/telrose_Belgique.webp';
import svaLuxembourg from '../img/telrose_Luxembourg.webp';
import svaSuisse from '../img/telrose_Suisse.webp';

function Profil() {

  const { width } = useScreenSize();

  const hostesseProfil = useFilterByIdParam(hostessesArray);

  function openFullscreen() {

    document.getElementById('photo-profil').style.cssText = "width: 100%;";
  }
  function closeFullscreen() {
    document.getElementById('photo-profil').style.width = "0%";
  }

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  if (hostesseProfil) {
    return (
      <>
        <Helmet>
          {hostesseProfil.map(hostesse => (
            <>
              <title key={hostesse.id}>{hostesse.metaTitle}</title>
              <meta name="description"
                content={hostesse.metaDescription} />
              <link rel="canonical" href={`https://allodial.fr/profil-hotesse/${hostesse.id}/${hostesse.name}`} />
            </>
          ))}
        </Helmet>
        {hostesseProfil.map(hostesse => (
          <Container key={hostesse.id} className="mt-4" fluid={"lg"}>
            <div id="photo-profil" className="photo-profil px-2">
              <Image onClick={closeFullscreen} className="rounded-4 shadow-lg" src={`/img/photos/700px/${hostesse.photo}700.webp`} fluid alt={hostesse.alt} />
            </div>
            <div className='card-hostesse-profil pb-2 text-center rounded-4 mb-3'>
              <div className="pb-3">
                <div className='p-4 pb-2 card-container-photo rounded-top-4'>
                  <Image onClick={openFullscreen} className='card-photo' src={`/img/photos/700px/${hostesse.photo}700.webp`} alt={hostesse.alt} />
                </div>
                <div className='px-2'>
                  <h2 className='card-hostesse-name pb-2'>{hostesse.name}</h2>
                  {hostesse.description}
                </div>
              </div>
              {width < 767 ?
                <>
                  <div>
                    <h3 className="mb-4">Appeler depuis la France :</h3>
                    <a className="text-decoration-none link-to-call" href="tel:0895900200"
                      onClick={() => handleClick({ category: "Bouton 'Appeler' - Page de profil", action: "Click to Call" })}>Appeler</a>
                    <br />
                    <span className="info-price"><small>Service 0,45 € / min + prix d'un appel</small></span>
                  </div>
                  <div>
                    <h3 className="mt-4">Pour les appels depuis l'étranger :</h3>
                    <a href="tel:090340840" onClick={() => handleClick({ category: "SVA Belgique - Page de profil", action: "Click to Call" })}>
                      <Image className="shadow my-2" width={200} src={svaBelgique} rounded alt="numéro cliquable de téléphone rose Belgique : 090340840" />
                    </a>
                  </div>
                  <div>
                    <a href="tel:90528006" onClick={() => handleClick({ category: "SVA Luxembourg - Page de profil", action: "Click to Call" })}>
                      <Image className="shadow my-2" width={200} src={svaLuxembourg} rounded alt="numéro cliquable de téléphone rose Luxembourg : 90528006" />
                    </a>
                  </div>
                  <div>
                    <a href="tel:0901494494" onClick={() => handleClick({ category: "SVA Suisse - Page de profil", action: "Click to Call" })}>
                      <Image className="shadow my-2" width={200} src={svaSuisse} rounded alt="numéro cliquable de téléphone rose Suisse : 0901494494" />
                    </a>
                  </div>
                </>
                :
                <>
                  <div>
                    <Image src={svaFrance} className="mb-3" alt="numéro de téléphone rose France : 0895900200" />
                  </div>
                  <div>
                    <Image className="shadow my-2" width={250} src={svaBelgique} rounded alt="numéro de téléphone rose Belgique : 090340840" />
                  </div>
                  <div>
                    <Image className="shadow my-2" width={250} src={svaLuxembourg} rounded alt="numéro de téléphone rose Luxembourg : 90528006" />
                  </div>
                  <div>
                    <Image className="shadow my-2" width={250} src={svaSuisse} rounded alt="numéro de téléphone rose Suisse : 0901494494" />
                  </div>
                </>
              }
            </div>
          </Container>
        ))}
      </>
    )
  }
  return (
    <Navigate to="/404" />
  )
}

export default Profil;