import hostessesArray from '../constants/HostessesArray';
import { Image, Row, Col, } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
function Card() {
  return (
    <Row as={'ul'} >
      {hostessesArray.map(hostesse => (
        <Col as={'li'} key={hostesse.id} xs={6} md={4} lg={3} className='mb-3'>
          <NavLink className="text-decoration-none" to={`/profil-hotesse/${hostesse.id}/${hostesse.name}`} caseSensitive end>
            <div className='card-hostesse pb-2 text-center rounded-3 h-100'>
              <div className='p-4 pb-2 card-container-photo rounded-top-3'>
                <Image className='card-photo' src={`/img/photos/300px/${hostesse.photo}300.webp`} alt={hostesse.alt} />
              </div>
              <div className='px-2'>
                <h3 className='card-hostesse-name'>{hostesse.name}</h3>
                <p className='lh-1 text-dark'><small>{hostesse.cardDescription}</small></p>
              </div>
            </div>
          </NavLink>
        </Col>
      ))
      }
    </Row >
  )
}

export default Card;
