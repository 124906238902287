import { Carousel, Image } from "react-bootstrap";
import photo1 from '../img/carousel(1).webp';
import photo1Mobile from '../img/carousel(1)576.webp';
import photo2 from '../img/carousel(2).webp';
import photo2Mobile from '../img/carousel(2)576.webp';
import photo3 from '../img/carousel(3).webp';
import photo3Mobile from '../img/carousel(3)576.webp';
import useScreenSize from "../hooks/useScreenSize";

function CarouselHeader() {

  const { width } = useScreenSize();

  return (
    <>
      <Carousel fade indicators={false} controls={false} className="carousel">
        <Carousel.Item interval={2500} >
          <Image fetchPriority="high" src={width < 576 ? photo1Mobile : photo1} fluid alt="Hôtesse de téléphone rose en lingerie" />
          <h1 className="position-absolute top-50 w-100 text-center"><strong>La plateforme numéro 1 de téléphone rose</strong></h1>
        </Carousel.Item>
        <Carousel.Item interval={2500} >
          <Image fetchpriority="low" src={width < 576 ? photo2Mobile : photo2} fluid alt="Plusieurs hôtesses de téléphone rose en lingerie" />
          <h1 className="position-absolute top-50 w-100 text-center"><strong>Hôtesses sexy à votre service</strong></h1>
        </Carousel.Item>
        <Carousel.Item interval={4000} >
          <Image fetchpriority="low" src={width < 576 ? photo3Mobile : photo3} fluid alt="Hôtesse de téléphone rose en lingerie" />
          <h1 className="position-absolute top-50 w-100 text-center"><strong>Appel direct au <br /> 0 895 900 200</strong></h1>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default CarouselHeader;