import useScreenSize from '../hooks/useScreenSize';
import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import HostesseProfil from '../pages/HostesseProfil';
import LegalNotice from '../pages/LegalNotice';
import ErrorPage from '../pages/404';

function Main({ setScrollToHostesses }) {

  const { pathname } = useLocation();

  const { height } = useScreenSize();

  useEffect(() => {
    if (document.getElementById("main").offsetHeight < height) {
      document.getElementById("root").style.height = "100vh";
    }
    else {
      document.getElementById("root").style.height = "auto";
    }
  }, [pathname, height])

  return (
    <main id="main">
      <Routes>
        <Route path="/" element={<Home />} setScrollToHostesses={setScrollToHostesses} />
        <Route path="/accueil" element={<Home setScrollToHostesses={setScrollToHostesses} />} />
        <Route path="profil-hotesse/:id/:name" element={<HostesseProfil />} />
        <Route path="mentions-legales" element={<LegalNotice />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="404" element={<ErrorPage />} />
      </Routes>
    </main>
  )
}

export default Main;