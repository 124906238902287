import { NavLink } from "react-router-dom";

function ErrorPage() {
  return (
    <div className="text-center">
      <h1 className="not-found mb-5">Page Not Found</h1>
      <NavLink className="link-home p-2" to="/">Retour à l'accueil</NavLink>
    </div>
  )
}

export default ErrorPage;