const hostessesArray = [
  {
    id: 1,
    name: "Marie",
    photo: "image(1)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Sa voix douce et sensuelle sait éveiller vos sens et vous transporter dans un univers de plaisirs intenses.",
    metaTitle: "Rencontrez Marie : Votre Hôtesse Téléphone Rose au 0895900200",
    metaDescription: "Laissez-vous tenter et rejoignez-moi pour une expérience téléphonique intense et délicieusement torride.",
    description:
      <>
        <p>Bonjour et bienvenue dans mon univers sensuel et envoûtant. Je m'appelle Marie, votre complice de plaisirs téléphoniques. Avec moi, oubliez la routine et laissez-vous transporter par des conversations enflammées et des échanges passionnés.</p>
        <p>Mon objectif est de vous offrir un moment unique, où chaque mot est soigneusement choisi pour éveiller vos sens et vous emmener au sommet de l'excitation. Que vous ayez des fantasmes à partager, des désirs à explorer ou simplement envie de vous évader, je suis là pour écouter, comprendre et répondre à toutes vos envies.</p>
        <p>Je suis une hôtesse attentive, sensuelle et pleine d'imagination. Ensemble, nous créerons des instants magiques et inoubliables, loin des tracas quotidiens. Alors n'hésitez plus, laissez-vous tenter et rejoignez-moi pour une expérience téléphonique intense et délicieusement torride.</p>
      </>
  },
  {
    id: 2,
    name: "Rachel",
    photo: "image(2)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle devine vos désirs les plus secrets et sait comment les satisfaire avec une écoute attentive et une compréhension profonde.",
    metaTitle: "Confidences avec Rachel au 0895900200",
    metaDescription: "composez le 0895900200 et laissez-moi vous emmener dans un monde de plaisirs et de sensations inoubliables.",
    description:
      <>
        <p>Bonjour, je m'appelle Rachel et je suis ici pour rendre vos moments de solitude bien plus excitants. Que vous ayez envie de discuter, de partager vos fantasmes les plus secrets ou simplement de vous évader de votre quotidien, je suis là pour vous écouter et vous faire vibrer.</p>
        <p>Avec ma voix douce et sensuelle, je saurai vous transporter dans un univers de plaisirs et de délices. J'aime prendre le temps de vous connaître, d'écouter vos désirs et de vous offrir une expérience unique, adaptée à vos envies. Chaque appel est une aventure où nous pourrons explorer ensemble des territoires inconnus et excitants.</p>
        <p>Je suis disponible pour vous jour et nuit, prête à répondre à vos besoins les plus intimes. Que vous ayez envie d'une conversation coquine, d'une séance de role-play, ou simplement d'une oreille attentive pour écouter vos confessions, je suis la voix qui saura vous envoûter et vous satisfaire.</p>
        <p>Alors, n'hésitez plus, composez mon numéro et laissez-moi vous emmener dans un monde de plaisirs et de sensations inoubliables. Je suis impatiente de faire votre connaissance et de partager avec vous des moments intenses et délicieux.</p>
      </>
  },
  {
    id: 3,
    name: "Catherine",
    photo: "image(3)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle garantit la confidentialité de vos échanges, vous permettant de vous exprimer librement en toute sécurité.",
    metaTitle: "Séduction en Ligne : Catherine Vous Attend au 0895900200",
    metaDescription: "Laissez-vous emporter par ma sensualité et mes mots qui sauront éveiller tous vos sens.",
    description:
      <>
        <p>Bonjour, je m'appelle Catherine, et je suis ravie de vous accueillir dans mon monde de fantasmes et de plaisirs. Douce, sensuelle et à l'écoute, je suis ici pour vous offrir un moment unique et intense. Que vous ayez envie de discuter, de vous confier ou de laisser libre cours à vos désirs les plus secrets, je suis là pour vous.</p>
        <p>Avec ma voix envoûtante et ma capacité à m'adapter à vos envies, je saurai vous faire vivre une expérience inoubliable. Chaque appel est une nouvelle aventure, et je suis impatiente de découvrir la vôtre. Laissez-vous emporter par ma sensualité et mes mots qui sauront éveiller tous vos sens.</p>
        <p>Je suis disponible pour vous à tout moment, prête à transformer vos fantasmes en réalité. N'hésitez plus, contactez-moi et plongeons ensemble dans un univers où seul le plaisir compte.</p>
      </>
  },
  {
    id: 4,
    name: "Julia",
    photo: "image(4)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Sa passion et son enthousiasme rendent chaque conversation ardente et inoubliable.",
    metaTitle: "Moments Intimes avec Julia : Téléphone Rose au 0895900200",
    metaDescription: "Je suis impatiente de vous entendre et de partager avec vous des moments inoubliables.",
    description:
      <>
        <p>Je m'appelle Julia, et je suis là pour rendre votre journée ou votre soirée inoubliable. Avec ma voix douce et sensuelle, je vous invite à entrer dans un monde où vos fantasmes les plus secrets prennent vie. Que vous ayez envie de discussions coquines, de confidences intimes ou de moments de pure détente, je suis là pour vous écouter et vous accompagner.</p>
        <p>Avec moi, chaque appel est unique et personnalisé. J'aime explorer vos désirs, jouer avec vos attentes et vous offrir une expérience aussi excitante que réconfortante. Que vous soyez en quête de complicité ou de passion brûlante, je saurai répondre à vos envies les plus profondes.</p>
        <p>N'hésitez pas à m'appeler et laissez-moi vous emporter dans un voyage de plaisir et de découverte. Je suis impatiente de vous entendre et de partager avec vous des moments inoubliables.</p>
      </>
  },
  {
    id: 5,
    name: "Eva",
    photo: "image(5)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Son charme naturel et sa chaleur humaine créent une connexion intime et authentique, vous mettant à l'aise dès le premier instant.",
    metaTitle: "Explorez vos Désirs avec Eva au 0895900200",
    metaDescription: "Ma voix douce et envoûtante saura vous transporter dans un monde où vos rêves les plus secrets deviennent réalité.",
    description:
      <>
        <p>Bonjour et bienvenue dans mon univers de plaisir et de séduction.</p>
        <p>Je m'appelle Eva, et je suis là pour vous offrir des moments d'évasion, de sensualité et de complicité. Avec moi, chaque conversation est une expérience unique, où vos désirs et fantasmes prennent vie dans un cadre de confiance et de respect.</p>
        <p>Ma voix douce et envoûtante saura vous transporter dans un monde où vos rêves les plus secrets deviennent réalité. Que vous ayez envie de partager une conversation coquine, explorer de nouveaux horizons ou simplement échapper à la routine, je suis à votre écoute, prête à satisfaire vos envies les plus intimes.</p>
        <p>N'hésitez pas à vous laisser tenter par cette aventure auditive, où chaque appel est une invitation à découvrir une facette plus osée et libérée de vous-même. Ensemble, nous créerons des moments mémorables, empreints de chaleur et de passion.</p>
        <p>Je suis impatiente de faire votre connaissance et de vous offrir une expérience téléphonique inoubliable.</p>
      </>
  },
  {
    id: 6,
    name: "Suzanne",
    photo: "image(6)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Dotée d'une voix envoûtante, elle sait éveiller les sens et créer une atmosphère érotique intense à chaque conversation.",
    metaTitle: "Dialogue Sensuel avec Suzanne au 0895900200",
    metaDescription: "Appelez-moi et plongeons ensemble dans un voyage de délices et de passion.",
    description:
      <>
        <p>Bonjour, je suis Suzanne, une voix douce et sensuelle prête à vous emmener dans un monde de plaisir et de fantasmes. Avec moi, chaque conversation devient une aventure excitante et intime. Je suis ici pour écouter vos désirs, répondre à vos envies et créer un moment unique rien que pour vous.</p>
        <p>Laissez-moi vous séduire par mes mots et vous transporter dans un univers où vos rêves les plus secrets peuvent devenir réalité. Que vous cherchiez de la compagnie, une oreille attentive ou une expérience érotique inoubliable, je suis à votre disposition pour rendre chaque instant magique.</p>
        <p>Appelez-moi et plongeons ensemble dans un voyage de délices et de passion.</p>
      </>
  },
  {
    id: 7,
    name: "Sophie",
    photo: "image(7)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "À l'écoute des désirs et des fantasmes, elle sait répondre avec précision aux attentes de ses interlocuteurs.",
    metaTitle: "Découvrez Sophie : Votre Évasion Téléphonique au 0895900200",
    metaDescription: "Laissez-moi vous guider dans un monde de passion et de désir.",
    description:
      <>
        <p>Bienvenue ! Vous cherchez à pimenter votre soirée avec une conversation intime et enivrante ?</p>
        <p>Laissez-moi vous guider dans un monde de passion et de désir. En tant qu'hôtesse de téléphone rose, je suis là pour vous écouter, vous comprendre et vous emmener dans un voyage sensuel où vos fantasmes les plus profonds deviennent réalité par le pouvoir des mots.</p>
        <p>Que vous ayez besoin de réconfort, de conseils ou simplement de partager vos pensées les plus intimes, je suis là pour vous offrir une compagnie chaleureuse et sans jugement.</p>
        <p>Préparez-vous à vivre une expérience inoubliable où chaque conversation est une exploration de vos désirs les plus secrets.</p>
      </>
  },
  {
    id: 8,
    name: "Mia",
    photo: "image(8)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle possède une imagination débordante, capable de transformer chaque appel en une aventure sensuelle unique.",
    metaTitle: "Laissez-vous Charmer par Mia au 0895900200",
    metaDescription: "Laissez-vous emporter par mes mots et laissez votre imagination s'épanouir dans un monde de fantaisie et de plaisir.",
    description:
      <>
        <p>Bonjour, je suis Mia, votre douce confidente au bout du fil. Avec une voix suave et un esprit ouvert, je suis là pour vous écouter, vous comprendre et vous emmener dans un voyage sensuel et exaltant.</p>
        <p>Que vous ayez besoin d'une oreille attentive pour partager vos désirs les plus profonds ou que vous recherchiez une expérience intime et passionnée, je suis là pour vous offrir un moment de plaisir sans jugement.</p>
        <p>Laissez-vous emporter par mes mots et laissez votre imagination s'épanouir dans un monde de fantaisie et de plaisir.</p>
        <p>Appelez-moi et laissez-moi vous guider vers des moments inoubliables et enivrants.</p>
      </>
  },
  {
    id: 9,
    name: "Bella",
    photo: "image(9)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle garantit un échange discret et sécurisé, respectant la confidentialité et les limites de chacun.",
    metaTitle: "Plaisirs Partagés : Découvrez Aria au 0895900200",
    metaDescription: "Je suis là pour réaliser vos fantasmes les plus profonds.",
    description:
      <>
        <p>Bienvenue dans le monde sensuel et exaltant de l'érotisme vocal, où chaque mot est une caresse et chaque soupir une invitation à l'extase. Laissez-moi être votre guide dans ce voyage envoûtant, où nos voix se rencontrent dans une danse intime et passionnée.</p>
        <p>Je suis là pour réaliser vos fantasmes les plus profonds, pour vous écouter sans jugement et pour vous emmener vers des sommets de plaisir que vous n'avez jamais osé explorer auparavant. Avec moi, chaque conversation est une aventure unique, une évasion de la routine quotidienne vers un monde où le désir est roi et où toutes vos envies sont satisfaites.</p>
        <p>Que vous recherchiez une conversation douce et romantique ou une exploration audacieuse de vos désirs les plus sauvages, je suis là pour vous offrir une expérience inoubliable. Avec ma voix douce et envoûtante, je vais vous emmener dans un voyage sensoriel où le temps s'arrête et où seul le plaisir compte.</p>
        <p>Osez franchir le pas et plongez dans l'univers enivrant du téléphone rose avec moi. Je vous promets une expérience qui restera gravée dans votre mémoire longtemps après que la conversation se soit terminée.</p>
      </>
  },
  {
    id: 10,
    name: "Anna",
    photo: "image(10)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Sa personnalité chaleureuse met immédiatement les clients à l'aise, favorisant un climat de confiance et de complicité.",
    metaTitle: "Conversation Privée avec Anna au 0895900200",
    metaDescription: "Entrez dans mon monde où le plaisir n'a pas de limites.",
    description:
      <>
        <p>Bienvenue ! Je suis ici pour vous offrir un moment de douceur, de complicité et de plaisir.</p>
        <p>Je suis votre confidente, votre complice dans les désirs les plus intimes. </p>
        <p>Avec moi, chaque mot, chaque soupir est une promesse de satisfaction. Laissez-vous emporter par ma voix sensuelle et laissez vos fantasmes les plus profonds se réaliser.</p>
        <p>Je suis là pour vous écouter, pour vous comprendre et pour vous faire vibrer. Entrez dans mon monde où le plaisir n'a pas de limites.</p>
      </>
  },
  {
    id: 11,
    name: "Julie",
    photo: "image(11)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle maîtrise l'art de la séduction, avec une capacité à provoquer et à exciter par ses mots et son attitude.",
    metaTitle: "Intimité et Séduction : Julie au 0895900200",
    metaDescription: "Chaque appel est une occasion de vous connecter avec moi d'une manière authentique et passionnée.",
    description:
      <>
        <p>Je suis votre hôtesse dévouée pour vous guider à travers un voyage exquis de plaisir et de désir. Mon nom est Julie, et je suis ici pour réaliser vos fantasmes les plus secrets et éveiller vos sens.</p>
        <p>Avec une voix douce et envoûtante, je vous emmènerai dans un univers où vos désirs prennent vie. Que vous recherchiez une conversation intime, une écoute attentive ou des jeux de rôle passionnés, je suis là pour vous offrir une expérience inoubliable.</p>
        <p>Avec moi, vous trouverez un espace sûr et sans jugement pour explorer vos fantasmes les plus profonds. Chaque appel est une occasion de vous connecter avec moi d'une manière authentique et passionnée.</p>
        <p>Alors, laissez-vous emporter par la magie de notre conversation. Appelez-moi maintenant et laissez-moi vous guider vers un monde de plaisir et d'extase.</p>
      </>
  },
  {
    id: 12,
    name: "Jade",
    photo: "image(12)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Vos secrets sont en sécurité avec elle, garantissant une expérience intime et confidentielle.",
    metaTitle: "Avec Jade : Expériences Sensuelles au 0895900200",
    metaDescription: "Avec moi, vous découvrirez un monde où vos désirs les plus intimes prennent vie dans l'écho de ma voix.",
    description:
      <>
        <p>Bienvenue ! Je suis Jade, votre douce et sensuelle hôtesse de téléphone rose. Avec moi, vous découvrirez un monde où vos désirs les plus intimes prennent vie dans l'écho de ma voix.</p>
        <p>Je suis là pour écouter, pour vous comprendre, et pour vous emmener dans un voyage sensuel où chaque mot suscite un frisson, chaque soupir un désir ardent.</p>
        <p>Avec moi, vous êtes libre d'explorer vos fantasmes les plus profonds, sans jugement ni tabou. Laissez-vous emporter par la magie de l'instant, où chaque conversation est une danse envoûtante entre deux âmes cherchant le plaisir et l'extase.</p>
        <p>Osez franchir le pas et plongez dans un océan de passion et de plaisir avec moi, Anaïs, votre complice dans l'art de l'érotisme par téléphone.</p>
      </>
  }
]

export default hostessesArray;